<template>
  <div class="min-h-screen bg-gray-50 p-4">
    <!-- Header - more compact -->
    <div class="mb-4">
      <h1 class="text-lg font-medium text-gray-800">Paneli</h1>
      <p class="text-xs text-gray-500">Përmbledhje e metrikave të biznesit</p>
    </div>

    <!-- Stats grid - tighter spacing -->
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 mb-4">
      <!-- Sales Card - no rounded corners, more compact -->
      <div class="bg-white shadow-sm">
        <div class="flex items-center justify-between p-2 border-b border-gray-100">
          <div class="bg-sky-700 p-1.5">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
            </svg>
          </div>
          <h3 class="text-xs font-medium text-sky-700">SHITJE</h3>
        </div>
        <!-- Stats content -->
        <div class="p-2 space-y-1">
          <!-- Today's sales -->
          <div class="flex justify-between items-baseline">
            <span class="text-xs text-gray-500">Sot</span>
            <div class="text-right">
              <span class="text-lg font-semibold">{{ formatNumber(dashboardStore.reports?.today_sales) }}</span>
              <span class="text-xxs ml-1">LEK</span>
              <div class="text-xxs" :class="getComparisonClass(
                parseFloat(dashboardStore.reports?.today_sales || 0),
                parseFloat(dashboardStore.reports?.current_week_sales || 0) / 7
              )">
                {{ getComparisonText(
                  parseFloat(dashboardStore.reports?.today_sales || 0),
                  parseFloat(dashboardStore.reports?.current_week_sales || 0) / 7
                ) }}
              </div>
            </div>
          </div>

          <!-- Current Week -->
          <div class="flex justify-between items-baseline border-t pt-1">
            <span class="text-xs text-gray-500">Java</span>
            <div class="text-right">
              <span class="text-lg font-semibold">{{ formatNumber(dashboardStore.reports?.current_week_sales) }}</span>
              <span class="text-xxs font-thin ml-1">LEK</span>
              <div class="text-xxs" :class="getComparisonClass(
                parseFloat(dashboardStore.reports?.current_week_sales || 0),
                parseFloat(dashboardStore.reports?.previous_month_sales || 0) / 4
              )">
                {{ getComparisonText(
                  parseFloat(dashboardStore.reports?.current_week_sales || 0),
                  parseFloat(dashboardStore.reports?.previous_month_sales || 0) / 4,
                  'nga mesatarja mujore'
                ) }}
              </div>
            </div>
          </div>

          <!-- Current Month -->
          <div class="flex justify-between items-baseline border-t pt-1">
            <span class="text-xs text-gray-500">Muaji</span>
            <div class="text-right">
              <span class="text-lg font-semibold">{{ formatNumber(dashboardStore.reports?.current_month_sales) }}</span>
              <span class="text-xxs font-thin ml-1">LEK</span>
              <div class="text-xxs" :class="getComparisonClass(
                parseFloat(dashboardStore.reports?.current_month_sales || 0),
                parseFloat(dashboardStore.reports?.previous_month_sales || 0)
              )">
                {{ getComparisonText(
                  parseFloat(dashboardStore.reports?.current_month_sales || 0),
                  parseFloat(dashboardStore.reports?.previous_month_sales || 0),
                  'nga muaji i kaluar'
                ) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Purchases Card -->
      <div class="bg-white shadow-sm">
        <div class="flex items-center justify-between p-2 border-b border-gray-100">
          <div class="bg-cyan-700 p-1.5">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
          </div>
          <h3 class="text-xs font-medium text-cyan-700">BLERJE</h3>
        </div>
        <div class="p-2 space-y-1">
          <!-- Today's purchases -->
          <div class="flex justify-between items-baseline">
            <span class="text-xs text-gray-500">Sot</span>
            <div class="text-right">
              <span class="text-lg font-semibold">{{ formatNumber(dashboardStore.reports?.today_purchases) }}</span>
              <span class="text-xxs ml-1">LEK</span>
              <div class="text-xxs" :class="getComparisonClass(dashboardStore.reports?.today_purchases, dashboardStore.reports?.current_week_purchases / 7)">
                {{ getComparisonText(dashboardStore.reports?.today_purchases, dashboardStore.reports?.current_week_purchases / 7) }}
              </div>
            </div>
          </div>

          <!-- Current Week -->
          <div class="flex justify-between items-baseline border-t pt-1">
            <span class="text-xs text-gray-500">Java</span>
            <div class="text-right">
              <span class="text-lg font-semibold">{{ formatNumber(dashboardStore.reports?.current_week_purchases) }}</span>
              <span class="text-xxs font-thin ml-1">LEK</span>
              <div class="text-xxs" :class="getComparisonClass(dashboardStore.reports?.current_week_purchases / 7, dashboardStore.reports?.current_month_purchases / 30)">
                {{ getComparisonText(dashboardStore.reports?.current_week_purchases / 7, dashboardStore.reports?.current_month_purchases / 30, 'mesatare ditore') }}
              </div>
            </div>
          </div>

          <!-- Current Month -->
          <div class="flex justify-between items-baseline border-t pt-1">
            <span class="text-xs text-gray-500">Muaji</span>
            <div class="text-right">
              <span class="text-lg font-semibold">{{ formatNumber(dashboardStore.reports?.current_month_purchases) }}</span>
              <span class="text-xxs font-thin ml-1">LEK</span>
              <div class="text-xxs" :class="getComparisonClass(dashboardStore.reports?.current_month_purchases, dashboardStore.reports?.previous_month_purchases)">
                {{ getComparisonText(dashboardStore.reports?.current_month_purchases, dashboardStore.reports?.previous_month_purchases, 'nga muaji i kaluar') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Weekly Comparison Chart -->
      <WeeklyComparisonChart :sales-data="[
        { 
          day: 'Sot', 
          sales: dashboardStore.reports?.today_sales || 0,
          purchases: dashboardStore.reports?.today_purchases || 0
        },
        { 
          day: 'Java', 
          sales: dashboardStore.reports?.current_week_sales || 0,
          purchases: dashboardStore.reports?.current_week_purchases || 0
        },
        { 
          day: 'Muaji', 
          sales: dashboardStore.reports?.current_month_sales || 0,
          purchases: dashboardStore.reports?.current_month_purchases || 0
        }
      ]" />
    </div>

    <!-- Articles section -->
    <div class="border-b border-gray-200 mb-3 pb-1">
      <span class="text-xs font-medium text-gray-600">ARTIKUJ</span>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
      <!-- New Articles -->
      <div class="bg-white shadow-sm">
        <div class="bg-sky-700 px-2 py-1.5">
          <h3 class="text-white text-xs font-medium">TË SAPO ARDHURA</h3>
        </div>
        <div class="p-2">
          <ul class="divide-y divide-gray-100">
            <li v-for="article in dashboardStore.new_articles" 
                :key="article.guid"
                class="py-1.5 flex justify-between items-center text-xs">
              <span class="text-gray-600 truncate max-w-[150px]">{{ article.name }}</span>
              <span class="font-medium">{{ article.total_quantity }}</span>
            </li>
          </ul>
        </div>
      </div>

      <!-- Artikuj në Limit -->
      <div class="bg-white shadow-sm">
        <div class="bg-red-700 px-2 py-1.5">
          <h3 class="text-white text-xs font-medium">NË LIMIT</h3>
        </div>
        <div class="p-2">
          <ul class="divide-y divide-gray-100">
            <li v-for="article in dashboardStore.stock_limit_articles" 
                :key="article.guid"
                class="py-1.5 flex justify-between items-center text-xs">
              <span class="text-gray-600 truncate max-w-[150px]">{{ article.name }}</span>
              <span class="font-medium">{{ article.total_quantity }}</span>
            </li>
          </ul>
        </div>
      </div>

      <!-- Artikujt më të Shitur -->
      <div class="bg-white shadow-sm">
        <div class="bg-green-700 px-2 py-1.5">
          <h3 class="text-white text-xs font-medium">MË TË SHITUR</h3>
        </div>
        <div class="p-2">
          <ul class="divide-y divide-gray-100">
            <li v-for="article in dashboardStore.most_sold_articles" 
                :key="article.guid"
                class="py-1.5 flex justify-between items-center text-xs">
              <span class="text-gray-600 truncate max-w-[150px]">{{ article.name }}</span>
              <span class="font-medium">{{ Math.abs(article.total_quantity) }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Debtors section -->
    <div class="border-b border-gray-200 mb-3 mt-4 pb-1">
      <span class="text-xs font-medium text-gray-600">DETYRIME</span>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
      <!-- Suppliers Debt -->
      <div class="bg-white shadow-sm">
        <div class="bg-purple-700 px-2 py-1.5">
          <h3 class="text-white text-xs font-medium">FURNITORË</h3>
        </div>
        <div class="p-2">
          <ul class="divide-y divide-gray-100">
            <li v-for="debtor in dashboardStore.debtors.filter(d => d.invoice_type === 'supplier')" 
                :key="debtor.group_key"
                class="py-1.5 flex justify-between items-center text-xs">
              <div>
                <span class="text-gray-600 truncate max-w-[150px]">{{ debtor.party_name }}</span>
                <div class="text-gray-400 text-xxs">Total: {{ formatNumber(debtor.total_amount) }} LEK</div>
              </div>
              <span class="font-medium text-red-600">
                {{ formatNumber(debtor.total_amount - debtor.total_paid) }} LEK
              </span>
            </li>
          </ul>
        </div>
      </div>

      <!-- Customers Debt -->
      <div class="bg-white shadow-sm">
        <div class="bg-indigo-700 px-2 py-1.5">
          <h3 class="text-white text-xs font-medium">KLIENTË</h3>
        </div>
        <div class="p-2">
          <ul class="divide-y divide-gray-100">
            <li v-for="debtor in dashboardStore.debtors.filter(d => d.invoice_type === 'customer')" 
                :key="debtor.group_key"
                class="py-1.5 flex justify-between items-center text-xs">
              <div>
                <span class="text-gray-600 truncate max-w-[150px]">{{ debtor.party_name }}</span>
                <div class="text-gray-400 text-xxs">Total: {{ formatNumber(debtor.total_amount) }} LEK</div>
              </div>
              <span class="font-medium text-red-600">
                {{ formatNumber(debtor.total_amount - debtor.total_paid) }} LEK
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Add search bar above the documents section -->
    <div class="border-b border-gray-200 mb-3 mt-4 pb-1 flex items-center justify-between">
      <span class="text-xs font-medium text-gray-600">DOKUMENTA TË HAPUR</span>
      <div class="relative">
        <input v-model="searchQuery" 
               type="text" 
               placeholder="Kërko dokumente..." 
               class="text-xs px-2 py-1 border border-gray-200 rounded-sm focus:outline-none focus:border-sky-500 w-48">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
      </div>
    </div>

    <!-- Documents Section -->
    <div class="bg-white shadow-sm">
      <div class="overflow-x-auto">
        <table class="w-full text-xs">
          <thead>
            <tr class="bg-gray-50 text-gray-500">
              <th class="py-2 px-2 text-left font-medium">PROCESI</th>
              <th class="py-2 px-2 text-left font-medium">DATA</th>
              <th class="py-2 px-2 text-left font-medium">ORIGJINA/DESTINACIONI</th>
              <th class="py-2 px-2 text-left font-medium">HAPI</th>
              <th class="py-2 px-2 text-right font-medium">VLERA</th>
              <th class="py-2 px-2 text-left font-medium">PËRDORUESI</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(group, process_id) in filteredGroups" :key="process_id">
              <!-- Process Header - Collapsible -->
              <tr class="bg-gray-50 border-t border-b border-gray-200 hover:bg-gray-100 cursor-pointer"
                  @click="toggleGroup(process_id)">
                <td colspan="6" class="py-1.5 px-2">
                  <div class="flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" 
                         class="w-3 h-3 transform transition-transform"
                         :class="{'rotate-90': !collapsedGroups[process_id]}" 
                         fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                    <span class="font-medium text-gray-700">{{ group[0].name }}</span>
                    <span class="text-blue-500 bg-blue-50 px-1.5 py-0.5 text-xxs rounded-sm">
                      {{ group.length }} {{ group.length === 1 ? 'dokument' : 'dokumenta' }}
                    </span>
                  </div>
                </td>
              </tr>
              
              <!-- Process Documents - Collapsible Content -->
              <tr v-for="doc in group" 
                  v-show="!collapsedGroups[process_id]"
                  :key="doc.guid" 
                  class="hover:bg-gray-50 border-b border-gray-100 last:border-b-0">
                <td class="py-1.5 px-2 text-gray-400">
                  <span class="pl-4">#{{ doc.process_number }}</span>
                </td>
                <td class="py-1.5 px-2">{{ new Date(doc.created_at).toLocaleDateString('sq-AL') }}</td>
                <td class="py-1.5 px-2">
                  <div class="flex flex-col">
                    <span class="text-gray-600">
                      {{ getPartyName(doc, 'origin') }}
                    </span>
                    <span class="text-gray-400 text-xxs">
                      {{ getPartyName(doc, 'destination') }}
                    </span>
                  </div>
                </td>
                <td class="py-1.5 px-2">
                  <span class="text-orange-600">{{ doc.step_name }}</span>
                </td>
                <td class="py-1.5 px-2 text-right font-medium">
                  {{ formatNumber(doc.total_price) }} LEK
                </td>
                <td class="py-1.5 px-2 text-gray-500">{{ doc.user_name }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, getCurrentInstance, computed, ref } from 'vue'
import { useDashboardStore } from '@/stores/dashboard'
import { useProcessStore } from '@/stores/process'
import WeeklyComparisonChart from '@/components/WeeklyComparisonChart.vue'

const dashboardStore = useDashboardStore()
const processStore = useProcessStore()
const formatNumber = (value) => {
  if (!value) return 0
  // Convert string to number if needed
  const numValue = typeof value === 'string' ? parseFloat(value) : value
  return new Intl.NumberFormat('sq-AL').format(numValue)
}

const getComparisonClass = (current, previous) => {
  // Convert string values to numbers
  const currentNum = typeof current === 'string' ? parseFloat(current) : current
  const previousNum = typeof previous === 'string' ? parseFloat(previous) : previous
  
  if (currentNum === undefined || previousNum === undefined) return 'text-gray-400'
  // Handle case where previous is zero
  if (previousNum === 0) {
    return currentNum === 0 ? 'text-gray-400' : 'text-green-500'
  }
  const diff = ((currentNum - previousNum) / previousNum) * 100
  if (Math.abs(diff) < 1) return 'text-gray-400'
  return diff > 0 ? 'text-green-500' : 'text-red-500'
}
    
const getComparisonText = (current, previous, suffix = '') => {
  // Convert string values to numbers
  const currentNum = typeof current === 'string' ? parseFloat(current) : current
  const previousNum = typeof previous === 'string' ? parseFloat(previous) : previous
  
  if (currentNum === undefined || previousNum === undefined) return '-'
  // Handle case where previous is zero
  if (previousNum === 0) {
    if (currentNum === 0) return 'Nuk ka ndryshim ' + suffix
    return `Nga zero ${suffix}`.trim()
  }
  const diff = ((currentNum - previousNum) / previousNum) * 100
  if (Math.abs(diff) < 1) return 'Nuk ka ndryshim ' + suffix
  const prefix = diff > 0 ? '+' : ''
  return `${prefix}${Math.abs(Math.round(diff))}% ${suffix}`.trim()
}

const searchQuery = ref('')
const collapsedGroups = ref({})

const getPartyName = (doc, direction) => {
  const type = direction === 'origin' ? doc.origin_type : doc.destination_type
  const name = direction === 'origin' ? 
    (type === 1 ? doc.pos_origin_name : 
     type === 2 ? doc.warehouse_origin_name :
     type === 3 ? doc.supplier_origin_name :
     doc.customer_origin_name) :
    (type === 1 ? doc.pos_destination_name : 
     type === 2 ? doc.warehouse_destination_name :
     type === 3 ? doc.supplier_destination_name :
     doc.customer_destination_name)
  
  return name || 'N/A'
}

const filteredGroups = computed(() => {
  const query = searchQuery.value.toLowerCase().trim()
  const groups = processStore.process_documents.reduce((groups, doc) => {
    // Only add to groups if document matches search
    if (query === '' || // If no search, include all
        doc.name?.toLowerCase().includes(query) ||
        new Date(doc.created_at).toLocaleDateString('sq-AL').includes(query) ||
        doc.process_number?.toString().includes(query) ||
        doc.user_name?.toLowerCase().includes(query) ||
        doc.customer_destination_name?.toLowerCase().includes(query) ||
        doc.pos_origin_name?.toLowerCase().includes(query) ||
        doc.warehouse_origin_name?.toLowerCase().includes(query) ||
        doc.supplier_origin_name?.toLowerCase().includes(query) ||
        doc.step_name?.toLowerCase().includes(query) ||
        formatNumber(doc.total_price)?.includes(query)) {
      const key = doc.process_id
      if (!groups[key]) {
        groups[key] = []
      }
      groups[key].push(doc)
    }
    return groups
  }, {})

  return groups
})

const toggleGroup = (processId) => {
  collapsedGroups.value[processId] = !collapsedGroups.value[processId]
}

onMounted(() => {
	getCurrentInstance().appContext.config.globalProperties.$document.title = "Dashboard"
  dashboardStore.getReports()
  processStore.documents_filter_status = '0';
  processStore.filter_documents('')
})
</script>
