<template>
  <div class="bg-white shadow-sm col-span-2">
    <div class="flex items-center justify-between p-2 border-b border-gray-100">
      <div class="bg-blue-700 p-1.5">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
        </svg>
      </div>
      <h3 class="text-xs font-medium text-blue-700">KRAHASIMI</h3>
    </div>
    <div class="chart-container p-2">
      <canvas v-show="isMounted" ref="chartCanvas"></canvas>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watch, defineProps } from 'vue'
import Chart from 'chart.js/auto';
import {
  // Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const props = defineProps({
  salesData: {
    type: Array,
    default: () => ([
      { day: 'Sot', sales: 0, purchases: 0 },
      { day: 'Java', sales: 0, purchases: 0 },
      { day: 'Muaji', sales: 0, purchases: 0 }
    ])
  }
})

const chartCanvas = ref(null)
const chart = ref(null)
const isMounted = ref(false)

const formatNumber = (value) => {
  return new Intl.NumberFormat('sq-AL').format(value)
}

const createChart = () => {
  // Wait for next tick and ensure canvas is mounted
  if (!chartCanvas.value || !isMounted.value) return

  // Cleanup existing chart
  if (chart.value) {
    chart.value.destroy()
  }

  // Get context after ensuring canvas exists
  const ctx = chartCanvas.value.getContext('2d')
  if (!ctx) return

  // Create new chart
  chart.value = new Chart(ctx, {
    type: 'bar',
    data: {
      labels: props.salesData.map(item => item.day),
      datasets: [
        {
          label: 'Shitje',
          data: props.salesData.map(item => parseFloat(item.sales || 0)),
          backgroundColor: '#0369a1',
          barPercentage: 0.7,
          categoryPercentage: 0.8
        },
        {
          label: 'Blerje',
          data: props.salesData.map(item => parseFloat(item.purchases || 0)),
          backgroundColor: '#0e7490',
          barPercentage: 0.7,
          categoryPercentage: 0.8
        }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'top',
          labels: {
            boxWidth: 12,
            padding: 15,
            font: {
              size: 11
            }
          }
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              return `${context.dataset.label}: ${formatNumber(context.raw)} LEK`
            }
          }
        }
      },
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            color: '#f1f5f9'
          },
          ticks: {
            font: {
              size: 11
            },
            callback: (value) => {
              return formatNumber(value) + ' LEK'
            }
          }
        },
        x: {
          grid: {
            display: false
          },
          ticks: {
            font: {
              size: 11
            }
          }
        }
      }
    }
  })
}

onMounted(() => {
  // Set mounted flag
  // Use setTimeout to ensure DOM is fully ready
  setTimeout(() => {
  isMounted.value = true
    createChart()
  }, 1000)
})

watch(() => props.salesData, () => {
  if (isMounted.value) {
    setTimeout(() => {
      createChart()
    }, 0)
  }
}, { deep: true })

onBeforeUnmount(() => {
  if (chart.value) {
    chart.value.destroy()
  }
})
</script>

<style scoped>
.chart-container {
  position: relative;
  height: 200px;
  width: 100%;
}
</style> 
